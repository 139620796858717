import { Icon, IconType } from 'components/Icon';
import { RichText } from 'components/RichText';
import React, { ReactNode, useMemo } from 'react';
import styles from './OccupationTile.module.css';
import { DataList } from 'components/DataList';

interface Properties {
  children: ReactNode;
  amountIncludingReservations?: number;
  amountExcludingReservations?: number;
  reservedAmount?: number;
  icon: IconType;
  title: string;
  total?: number;
}

export const OccupationTile = ({
  children,
  icon,
  title,
  total,
  amountIncludingReservations,
  amountExcludingReservations,
  reservedAmount,
}: Properties) => {
  const data = useMemo(
    () =>
      [
        {
          label: 'Gereserveerde bedden',
          children:
            !!amountExcludingReservations && !!reservedAmount ? (
              <div className={styles.ratio}>
                <span className={styles.total}>{reservedAmount}</span>
              </div>
            ) : undefined,
        },
        {
          label: 'Bedden met medewerker',
          children:
            !!amountExcludingReservations && !!total ? (
              <div className={styles.ratio}>
                <span className={styles.currentAmount}>{amountExcludingReservations}</span>
                <span className={styles.total}> / {total}</span>
              </div>
            ) : undefined,
        },
        {
          label: 'Reserveringen + bezetting',
          children:
            !!amountIncludingReservations && !!total ? (
              <div className={styles.ratio}>
                <span className={styles.currentAmount}>{amountIncludingReservations}</span>
                <span className={styles.total}> / {total}</span>
              </div>
            ) : undefined,
        },
      ].filter((item) => !!item.children),
    [amountExcludingReservations, amountIncludingReservations, reservedAmount, total]
  );
  return (
    <div className={styles.container}>
      <div className={styles.upperContainer}>
        <div>
          <RichText>
            <h3>{title}</h3>
          </RichText>
          {!!data && (
            <div className={styles.dataListCard}>
              <DataList data={data} />
            </div>
          )}
        </div>
        <div className={styles.iconContainer}>
          <Icon type={icon} />
        </div>
      </div>
      {children}
    </div>
  );
};
